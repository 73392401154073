<div class="link-bank-btn-container mt-32">
  <a id="link-bank-btn_href" [routerLink]="urlPath">
    <pbb-svg-icon
      [title]="'COMMON.BUTTON.LINK_ANOTHER_BANK_ADD' | translate"
      [icon]="'add-btn'"
      class="icon-md"
    ></pbb-svg-icon>
  </a>
  <div class="mt-12 mat-body-2" id="link-bank-btn_label">{{ 'COMMON.BUTTON.LINK_ANOTHER_BANK_ADD' | translate }}</div>
</div>
