import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PbbSvgIconComponent } from '../pbb-svg-icon/pbb-svg-icon.component';

@Component({
  selector: 'pbb-link-bank',
  standalone: true,
  imports: [NgIf, MatButtonModule, RouterLink, MatIconModule, TranslateModule, PbbSvgIconComponent],
  templateUrl: './link-bank.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkBankComponent {
  @Input() urlPath = 'linkBank';
  @Input() email = '';
  @Input() showBackButton = false;
}
