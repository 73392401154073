<div class="flex-row flex-jc-none" id="link-bank_container">
  <pbb-svg-icon
    [icon]="'konek-logo'"
    [title]="'COMMON.LOGO_NAME' | translate"
    width="32px"
    height="32px"
    class="icon-md mr-16"
  ></pbb-svg-icon>
  <h1 id="link-bank_title">{{ 'COMMON.BUTTON.LINK_ANOTHER_BANK' | translate }}</h1>
</div>
<div class="mat-body-2 text-grey-subtitle mt-8" id="link-bank_subtitle">
  {{ 'COMMON.BUTTON.LINK_ANOTHER_BANK_SUBTITLE' | translate }}
</div>
<div class="flex-row mt-24">
  <div class="link-bank-email-container">
    <div class="subtitle-medium" id="link-bank_payment-method-title">
      {{ 'COMMON.BUTTON.LINK_ANOTHER_BANK_PAYMENT_METHOD_TITLE' | translate }}
    </div>
    <div class="mt-4 mat-body-2 text-grey-subtitle" id="link-bank_email">{{ email }}</div>
  </div>
  <a id="link-bank_href" [routerLink]="urlPath">
    <pbb-svg-icon
      [icon]="'add-btn'"
      [title]="'COMMON.BUTTON.LINK_ANOTHER_BANK_ADD' | translate"
      class="icon-md"
    ></pbb-svg-icon>
  </a>
</div>
<hr class="mt-24 mb-24" />
